import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

import { JushAdExpandedContent } from './components/JushAdExpandedContent/JushAdExpandedContent';
import { useJushAd } from './hooks/useJushAd';
import Background from './img/adBackground.svg';
import Logo from './img/jush-logo.svg';

export const JushAd = () => {
  const { t } = useTranslation('common');
  const {
    isMobile,
    isApple,
    isExpanded,
    shouldAnimate,
    handleOpen,
    handleClose,
  } = useJushAd();

  const { pathname } = useRouter();

  if (pathname.includes('/checkout')) {
    return null;
  }

  return (
    <div className="fixed bottom-[100px] z-50" data-testid="jush-ad">
      <button
        aria-label={t('Open')}
        type="button"
        className={cn(
          'relative',
          isExpanded ? 'cursor-auto' : 'cursor-pointer'
        )}
        onClick={handleOpen}
      >
        <Background
          className={cn(
            'absolute -left-0.5 bottom-0 size-auto drop-shadow-jush-ad',
            getClasses(shouldAnimate, isExpanded)
          )}
        />
        <Transition
          as="div"
          show={isExpanded}
          enter="transition-opacity duration-300 delay-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <JushAdExpandedContent
            isMobile={isMobile}
            isApple={isApple}
            onClose={handleClose}
          />
        </Transition>
        <Transition
          as="div"
          show={!isExpanded}
          enter="transition-opacity duration-500 delay-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Logo className={cn('absolute bottom-5 left-5')} />
        </Transition>
      </button>
    </div>
  );
};

const getClasses = (shouldAnimate: boolean, isExpanded: boolean) => {
  if (shouldAnimate) {
    return isExpanded ? 'animate-ad-expand' : 'animate-ad-collapse';
  }
  return null;
};
